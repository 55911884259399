import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/footer'
import Home from '../Pages/Home/home';
import Mentha from '../Pages/Mentha/mentha';
import Vetiver from '../Pages/Vetiver/vetiver';
import Basil from '../Pages/Basil/basil';
import Lemongrass from '../Pages/Lemongrass/lemongrass';
import Palmarosa from '../Pages/Palmarosa/palmarosa';
import Citronella from '../Pages/Citronella/citronella';
import { LINKS } from '../../utils/common';
import { Route, Routes } from 'react-router-dom';

const layout = () => {
    return (
        <main>
            <Header />
            <Routes>
                <Route path={LINKS.HOME} element={<Home />} />
                <Route path={LINKS.VETIVER} element={<Vetiver />} />
                <Route path={LINKS.MINT} element={<Mentha />} />
                <Route path={LINKS.BASIL} element={<Basil />} />
                <Route path={LINKS.PALMAROSA} element={<Palmarosa />} />
                <Route path={LINKS.LEMONGRASS} element={<Lemongrass />} />
                <Route path={LINKS.CITRONELLA} element={<Citronella />} />
            </Routes>
            <Footer />
        </main>
    );
};



export default layout;