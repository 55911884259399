import React from "react";
import ProductImage from '../../../img/pages/mentha.jpg';
import clazz from '../common.module.css';
import { isMobile } from "react-device-detect";

const mentha = () => {
    return (
        <div className={isMobile ? clazz.container_mobile : clazz.container}>
            <div>
                <img alt="mint field" className={isMobile ? clazz.mobile_image : clazz.image} src={ProductImage} />
                <div className={isMobile ? clazz.mobile_scrollable_text : clazz.scrollable_text}>
                    <p> <b>Mint Oil Family</b></p>
                    <p>
                        Metha oil, also known as mentha oil, is an exceptional product that holds significant sales potential. Sourced from high-quality mentha strains, this oil offers a range of enticing selling points that make it an attractive choice for customers.
                    </p><p>
                        First and foremost, the refreshing and uplifting aroma of metha oil sets it apart as a valuable ingredient in the fragrance industry. Its distinct minty and cooling scent adds a revitalizing touch to perfumes, cosmetics, and personal care products. This makes metha oil a sought-after option for manufacturers and individuals seeking products that exude a refreshing and invigorating scent.
                    </p><p>
                        Moreover, metha oil holds remarkable therapeutic properties, making it a popular choice in the wellness sector. The cooling and soothing effects it provides make it an ideal candidate for aromatherapy applications. By relieving stress, reducing mental fatigue, and promoting relaxation, metha oil appeals to customers looking for natural remedies to enhance their well-being.
                    </p><p>
                        Additionally, metha oil's antimicrobial and analgesic properties make it valuable in the field of skincare. It is often used in balms, lotions, and ointments to soothe skin irritations, reduce itching, and provide a cooling sensation. Customers seeking natural and effective skincare solutions will be drawn to products infused with metha oil, as it offers a refreshing and calming experience for the skin.
                    </p><p>
                        Furthermore, metha oil has diverse applications in the culinary and food industry. Its strong minty flavor adds a refreshing twist to various food and beverage products, including candies, chocolates, chewing gums, and teas. Its inclusion in these products enhances their taste and offers a cool sensation to consumers.
                    </p><p>
                        In summary, metha oil presents an array of sales points that make it an appealing product in multiple industries. From its refreshing fragrance and therapeutic benefits in aromatherapy to its potential in skincare and culinary applications, this oil offers versatility and wide-ranging benefits. By leveraging these unique selling points, businesses can market metha oil as a premium and multifunctional product, attracting customers seeking natural, refreshing, and high-quality solutions for their fragrance, wellness, skincare, and culinary needs.
                    </p>
                </div>
            </div>

            <div className={clazz.inner_col_containers}>
                <div>
                    <p>
                        <b>Product : Peppermint Oil</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>HSN Code : 33012590</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>Product Reports</b>
                    </p>
                </div>
            </div>

            <div className={clazz.inner_col_containers}>
                <div>
                    <p>
                        <b>Product : Spearmint Oil</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>HSN Code : 33012510</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>Product Reports</b>
                    </p>
                </div>
            </div>


            <div className={clazz.inner_col_containers}>
                <div>
                    <p>
                        <b>Product : Metha Oil</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>HSN Code : 33012590</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>Product Reports</b>
                    </p>
                </div>
            </div>

            <div className={clazz.inner_col_containers}>
                <div>
                    <p>
                        <b>Product : Metha Citrata</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>HSN Code : 33012590</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>Product Reports</b>
                    </p>
                </div>
            </div>

        </div>
    );
}

export default mentha;