import React, { useState } from "react";
import logo from '../../img/logo-2-80x100.png';
import clazz from './header.module.css';
import { Link } from 'react-router-dom';
import { LINKS } from '../../utils/common';
import { isMobile } from "react-device-detect";

const products = ["Basil", "Citronella", "Lemongrass", "Palmarosa", "Mints", "Vetiver"]

const getLink = (product) => {
    switch (product) {
        case "Basil":
            return LINKS.BASIL;
        case "Citronella":
            return LINKS.CITRONELLA;
        case "Lemongrass":
            return LINKS.LEMONGRASS;
        case "Palmarosa":
            return LINKS.PALMAROSA;
        case "Mints":
            return LINKS.MINT;
        case "Vetiver":
            return LINKS.VETIVER;
        default:
            return LINKS.HOME;
    }
}

const Header = () => {

    const [showProductDD, setShowProductDD] = useState(false);

    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const getMobileNavIcon = () => {
        if (mobileNavOpen) {
            return <>&#215;</>
        }
        return <>&#9776;</>
    }

    const toggleMenu = () => {
        setMobileNavOpen(!mobileNavOpen)
    }


    const mobileMenu = () => {
        return (
            <div className={mobileNavOpen ? clazz.hamburger_open : clazz.hamburger}>
                <div className={clazz.menu_toggle} onClick={() => toggleMenu()}>{getMobileNavIcon()}</div>
                {mobileNavOpen &&
                    <div className={clazz.menu_container}>
                        <ul className={clazz.menu} id={clazz.menu} >
                            <li onClick={() => toggleMenu()}>
                                <Link to={LINKS.HOME}>Home</Link>
                            </li>
                            <li onClick={() => toggleMenu()}>
                                <a>About Us</a>
                            </li>
                            <li>
                                Products
                            </li>
                            {products.sort().map((i) => {
                                return (
                                    <li onClick={() => toggleMenu()}>
                                        <Link to={getLink(i)}>&nbsp;&nbsp;&nbsp;&nbsp; {i}</Link>
                                    </li>
                                );
                            })}
                            <li onClick={() => toggleMenu()}>
                                <a>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        );
    }

    const largeScreenMenu = () => {
        return (
            <ul>
                <li>
                    <Link to={LINKS.HOME}>Home</Link>
                </li>
                <li>
                    <a>About Us</a>
                </li>
                <li
                    onMouseEnter={() => setShowProductDD(true)}
                    onMouseLeave={() => setShowProductDD(false)}
                >
                    <a>Products</a>
                    <div
                        className={clazz.submenu}
                        style={{ display: showProductDD ? "" : "none" }}>
                        <ul>
                            {products.sort().map((i) => {
                                return (
                                    <li>
                                        <Link to={getLink(i)}>{i}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </li>
                <li>
                    <a>Contact Us</a>
                </li>
            </ul>
        );
    }

    return (
        <>
            <div className={clazz.header}>
                <div>
                    <img alt="logo" src={logo} height="60px" />
                    <p>
                        SUVASAA
                    </p>
                </div>
                {isMobile ? mobileMenu() : largeScreenMenu()}
            </div>
        </>
    );
}

export default Header;