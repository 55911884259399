import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import c1 from '../../img/carousel/crousel_01.jpg';
import c2 from '../../img/carousel/crousel_02.jpg';
import c3 from '../../img/carousel/crousel_03.jpg';
import c4 from '../../img/carousel/crousel_04.jpg';
import clazz from './imageCarousel.module.css';

const imageCarousel = () => {
    return (
        <div style={{position: 'relative', zIndex:0}}>
            <Carousel autoPlay={true} infiniteLoop={true} interval={5000} showStatus={false} showThumbs={false}>
                <div>
                    <img src={c1} height="350px" style={{ opacity: 0.8 }} />
                    <p className={clazz.legend}>
                        <b>Purity</b>
                        <br />
                        Uncompromising quality at every step.
                    </p>
                </div>
                <div>
                    <img src={c2} height="350px" style={{ opacity: 0.8 }} />
                    <p className={clazz.legend}>
                        <b>Assurance</b>
                        <br />
                        Your confidence, our guarantee.
                    </p>
                </div>
                <div>
                    <img src={c3} height="350px" style={{ opacity: 0.8 }} />
                    <p className={clazz.legend}>
                        <b>Unadulterated Purity</b>
                        <br />
                        Uncompromising purity, undiluted essence.
                    </p>
                </div>
                <div>
                    <img src={c4} height="350px" style={{ opacity: 0.8 }} />
                    <p className={clazz.legend}>
                        <b>Reliable Supply Chain</b>
                        <br />
                        A dependable chain for consistent excellence.
                    </p>
                </div>
            </Carousel>
        </div>
    );
};

export default imageCarousel;