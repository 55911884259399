import React from "react";
import ProductImage from '../../../img/pages/citronella.jpg';
import clazz from '../common.module.css';
import { isMobile } from "react-device-detect";

const vetiver = () => {
    return (
        <div className={isMobile ? clazz.container_mobile : clazz.container}>
            <div>
                <img alt="citronella field" className={isMobile ? clazz.mobile_image : clazz.image} src={ProductImage} />
                <div className={isMobile ? clazz.mobile_scrollable_text : clazz.scrollable_text}>
                    <p> <b>Citronella</b></p>
                    <p>
                        Citronella oil is a remarkable product renowned for its various benefits. Derived from the leaves and stems of the citronella grass, this oil possesses a distinctively fresh, citrusy, and lemon-like aroma that is instantly recognizable. Its unique fragrance makes it a popular ingredient in perfumes, candles, and natural air fresheners, adding a delightful and invigorating scent to any space.
                    </p><p>
                        One of the most well-known properties of citronella oil is its effectiveness as a natural insect repellent. Its strong and powerful aroma acts as a deterrent to mosquitoes and other pesky insects, making it a sought-after choice for outdoor activities and creating a bug-free environment. Citronella oil offers a natural and safer alternative to conventional chemical-based repellents, allowing individuals to enjoy the outdoors without the hassle of biting insects.
                    </p><p>
                        In addition to its insect-repellent properties, citronella oil is also valued for its calming and uplifting effects. It is often used in aromatherapy to promote relaxation, reduce anxiety, and create a soothing atmosphere. The refreshing aroma of citronella oil can help relieve stress and rejuvenate the mind, offering a natural way to enhance well-being.
                    </p><p>
                        Furthermore, citronella oil has antimicrobial properties that make it beneficial for skincare applications. It can be used in skincare formulations to help cleanse and purify the skin, making it a popular choice for products targeting oily or acne-prone skin. Additionally, its astringent properties may assist in reducing the appearance of pores and promoting a more balanced complexion.
                    </p><p>
                        In summary, citronella oil offers a range of benefits that make it a highly versatile and sought-after product. From its effective insect-repellent properties and calming effects in aromatherapy to its potential in skincare, this oil is a natural and practical solution for individuals seeking a fresh and invigorating experience. Businesses can leverage the unique qualities of citronella oil to provide customers with a natural and safer alternative for insect control, relaxation, and skincare needs.
                    </p>
                </div>
            </div>

            <div className={clazz.inner_col_containers}>
                <div>
                    <p>
                        <b>HSN Code : 33011910</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>Product Reports</b>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default vetiver;