import React from "react";
import ProductImage from '../../../img/pages/lemongrass.jpg';
import clazz from '../common.module.css';
import { isMobile } from "react-device-detect";

const lemongrass = () => {
    return (
        <div className={isMobile ? clazz.container_mobile : clazz.container}>
            <div>
                <img alt="lemongrass field" className={isMobile ? clazz.mobile_image : clazz.image} src={ProductImage} />
                <div className={isMobile ? clazz.mobile_scrollable_text : clazz.scrollable_text}>
                    <p> <b>Lemongrass</b></p>
                    <p>
                        Lemongrass oil is a remarkable product that offers a myriad of benefits. Derived from the lemongrass plant, this oil possesses a distinct citrusy and refreshing aroma that is both invigorating and uplifting. Its captivating fragrance makes it a popular ingredient in perfumes, soaps, and candles, adding a zesty and vibrant touch to various products.
                    </p><p>
                        Beyond its delightful scent, lemongrass oil holds significant therapeutic properties. It is known for its calming and soothing effects, helping to alleviate stress, anxiety, and mental fatigue. The aromatic qualities of lemongrass oil make it a valuable component in aromatherapy, providing a rejuvenating and relaxing experience for individuals seeking natural remedies to enhance their well-being.
                    </p><p>
                        Lemongrass oil also boasts remarkable benefits for skincare. With its antiseptic and astringent properties, it helps to cleanse and tone the skin, making it a valuable addition to cleansers, toners, and skincare formulations. Its refreshing qualities can help revitalize and brighten the complexion, while its antimicrobial properties may assist in managing various skin concerns.
                    </p><p>
                        Additionally, lemongrass oil has insect-repellent properties, making it a practical choice for natural pest control solutions. Its strong scent acts as a deterrent to mosquitoes and other insects, providing a safer alternative to chemical-based repellents.
                    </p><p>
                        In summary, lemongrass oil offers a wide range of benefits that make it a valuable product. From its captivating fragrance and therapeutic effects in aromatherapy to its potential in skincare and natural insect repellency, this oil is versatile and highly sought after. Businesses can leverage the unique qualities of lemongrass oil to offer customers a natural, refreshing, and high-quality solution for their fragrance, wellness, skincare, and pest control needs.
                    </p>
                </div>
            </div>

            <div className={clazz.inner_col_containers}>
                <div>
                    <p>
                        <b>HSN Code : 33012942</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>Product Reports</b>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default lemongrass;