import React from "react";
import ProductImage from '../../../img/pages/vetiver.jpg';
import clazz from '../common.module.css';
import { isMobile } from "react-device-detect";

const vetiver = () => {
    return (
        <div className={isMobile ? clazz.container_mobile : clazz.container}>
            <div>
                <img alt="vetiver field" className={isMobile ? clazz.mobile_image : clazz.image} src={ProductImage} />
                <div className={isMobile ? clazz.mobile_scrollable_text : clazz.scrollable_text}>
                    <p> <b>Vetiver</b></p>
                    <p>
                        Vetiver essential oil, also known as khus oil, is a highly valuable product from a sales point of view. With its exceptional quality derived from a CIMAP approved strain of vetiver, this oil offers a range of enticing selling points that make it an attractive choice for customers.
                    </p><p>
                        First and foremost, the soothing earthy aroma of vetiver oil sets it apart as a unique and captivating fragrance ingredient. Its distinct woody, smoky, and slightly sweet scent adds depth and complexity to perfumes and fragrances, making them more alluring and long-lasting. This makes vetiver oil a must-have for perfume manufacturers and fragrance enthusiasts looking to create captivating and memorable scents.
                    </p><p>
                        Additionally, vetiver oil's therapeutic properties make it highly sought-after in the wellness industry. Its calming and grounding effects on the mind and body make it a perfect fit for aromatherapy applications. By reducing anxiety, promoting relaxation, and improving sleep quality, vetiver oil appeals to customers seeking natural remedies for stress relief and emotional well-being.
                    </p><p>
                        The skincare industry also stands to benefit from the inclusion of vetiver oil in products. With its reputed anti-aging, antiseptic, and anti-inflammatory properties, this oil is a valuable addition to creams, lotions, and serums. Customers looking for natural and holistic skincare solutions will be drawn to products infused with vetiver oil, as it can help soothe and nourish the skin, promoting a healthy and radiant complexion.
                    </p><p>
                        Furthermore, the insect-repellent properties of vetiver oil make it a practical choice for those seeking effective and eco-friendly pest control solutions. With its ability to repel termites and other insects, vetiver oil offers a safer alternative to chemical-based repellents. This appeals to environmentally conscious customers looking to protect their homes and gardens without compromising their health or the environment.
                    </p><p>
                        In summary, vetiver oil boasts numerous sales points that make it an enticing product in various industries. From its captivating fragrance and therapeutic benefits in aromatherapy to its potential in skincare and insect repellency, this oil offers a versatile range of applications. Leveraging these unique selling points, businesses can market vetiver oil as a high-quality and multi-purpose product, attracting customers seeking natural, sustainable, and effective solutions for their fragrance, wellness, skincare, and pest control needs.
                    </p>
                </div>
            </div>

            <div className={clazz.inner_col_containers}>
                <div>
                    <p>
                        <b>HSN Code : 33012990</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>Product Reports</b>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default vetiver;