import React from "react";
import mentha from '../../img/products/product_mentha.jpg';
import citronella from '../../img/products/product_citronella.jpg';
import basil from '../../img/products/product_basil.jpg';
import lemongrass from '../../img/products/product_lemongrass.jpg';
import palmrosa from '../../img/products/product_palmrosa.jpg';
import vetiver from '../../img/products/product_vetiver.jpg';
import clazz from './productLine.module.css';
import { Link } from 'react-router-dom';
import { LINKS } from '../../utils/common';
import { isMobile } from "react-device-detect";


const productLine = () => {
    return (
        <div className={isMobile ? clazz.products_mobile : clazz.products}>
            <div>
                <Link to={LINKS.MINT}> <img alt="icon" src={mentha} /> </Link>
                <p>
                    Mint
                </p>
            </div>
            <div>
                <Link to={LINKS.BASIL}> <img alt="icon" src={basil} /> </Link>
                <p>
                    Basil
                </p>
            </div>
            <div>
                <Link to={LINKS.LEMONGRASS}> <img alt="icon" src={lemongrass} /> </Link>
                <p>
                    Lemongrass
                </p>
            </div>
            <div>
                <Link to={LINKS.CITRONELLA}> <img alt="icon" src={citronella} /> </Link>
                <p>
                    Citronella
                </p>
            </div>
            <div>
                <Link to={LINKS.PALMAROSA}> <img alt="icon" src={palmrosa} /> </Link>
                <p>
                    Palmarosa
                </p>
            </div>
            <div>
                <Link to={LINKS.VETIVER}> <img src={vetiver} /> </Link>
                <p>
                    Vetiver
                </p>
            </div>
        </div>

    );
}

export default productLine;