import React from "react";
import ImageCarousel from '../../Carousel/imageCarousel';
import ProductLine from '../../Pages/productLine';
import clazz from './home.module.css';
import aboutImage from '../../../img/mid_section_img.jpg';
import { isMobile } from "react-device-detect";


const home = () => {
    return (
        <>
            {!isMobile && <ImageCarousel />}
            <ProductLine />
            <div className={isMobile ? clazz.about_us_mobile : clazz.about_us_div}>
                {!isMobile &&
                    <div>
                        <img alt="oil" src={aboutImage} />
                    </div>
                }
                <div>
                    <p>
                        <b>About Us</b>
                        <br />
                        <br />
                        We are dedicated to bringing you the finest oils from this ancient land of Ayurveda and natural remedies.
                        <br />
                        <br />
                        India has a rich history and heritage of producing high-quality essential oils, and we have established strong relationships with trusted suppliers across the country. Our oils are meticulously sourced from organic and sustainable farms, ensuring their purity and potency.
                        <br />
                        <br />
                        By importing exclusively from India, we support local farmers and artisans, fostering fair trade practices and contributing to the socio-economic development of rural communities. Our commitment to sustainability extends to environmentally friendly packaging and shipping methods, minimizing our ecological footprint.
                    </p>
                </div>
            </div>
        </>
    );
}

export default home;