import React from "react";
import clazz from './footer.module.css';
import { isMobile } from "react-device-detect";

const footer = () => {
    return (
        <div className={isMobile ? clazz.container_mobile : clazz.container}>
            <div>
                <p>
                    <b>USA</b>
                    <br />
                    <br />
                    12655 Morningpark Cir
                    <br />
                    Alpharetta, Georgia
                    <br />
                    30004
                </p>
            </div>

            <div>
                <p>
                    <b>India</b>
                    <br />
                    <br />
                    1/61 Vijayant Khand
                    <br />
                    Gomtinagar, Lucknow
                    <br />
                    226010
                </p>
            </div>

            <div>
                <p>
                    <b>Contact Us</b>
                    <br />
                    <br />
                    Phone : +91 xxx-xxx-xxxx
                    <br />
                    Phone : +1 678-779-9275
                    <br />
                    Email: admin@suvasaa.com
                </p>
            </div>
        </div>
    );
}

export default footer;