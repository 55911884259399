import React from "react";
import ProductImage from '../../../img/pages/palmarosa.jpg';
import clazz from '../common.module.css';
import { isMobile } from "react-device-detect";

const palmarosa = () => {
    return (
        <div className={isMobile ? clazz.container_mobile : clazz.container}>
            <div>
                <img alt="palmarosa field" className={isMobile ? clazz.mobile_image : clazz.image} src={ProductImage} />
                <div className={isMobile ? clazz.mobile_scrollable_text : clazz.scrollable_text}>
                    <p> <b>Palmarosa</b></p>
                    <p>
                        Palmarosa oil is a remarkable product with immense potential. Extracted from the palmarosa plant, this oil offers a range of enticing benefits that will captivate customers.
                    </p><p>
                        First and foremost, the floral and rosy aroma of palmarosa oil creates a delightful and uplifting fragrance. Its unique scent adds a touch of elegance and allure to perfumes, soaps, and cosmetics.
                    </p><p>
                        Palmarosa oil also possesses therapeutic properties. It has calming and balancing effects, promoting relaxation, reducing stress, and uplifting the mood. This makes it a valuable tool for enhancing overall well-being.
                    </p><p>
                        In addition, palmarosa oil has impressive benefits for skincare. It has antibacterial and antiseptic properties, making it effective in addressing skin concerns such as acne, blemishes, and inflammation. By incorporating palmarosa oil into skincare formulations, customers can enjoy a natural and effective solution for nurturing and rejuvenating their skin.
                    </p><p>
                        Furthermore, palmarosa oil is versatile in natural perfumery and fragrance blending. Its aroma blends beautifully with other essential oils, adding depth and complexity to fragrances. It can be used as a top note or middle note to create captivating and sophisticated scent compositions.
                    </p><p>
                        Palmarosa oil offers a wide range of benefits, making it a valuable addition to various industries. Whether customers are seeking a delightful fragrance, therapeutic effects, skincare benefits, or aromatic experiences, palmarosa oil provides a versatile and high-quality solution.
                    </p>

                </div>
            </div>

            <div className={clazz.inner_col_containers}>
                <div>
                    <p>
                        <b>HSN Code : 33012933</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>Product Reports</b>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default palmarosa;