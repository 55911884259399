import React from "react";
import ProductImage from '../../../img/pages/basil.jpg';
import clazz from '../common.module.css';
import { isMobile } from "react-device-detect";

const basil = () => {
    return (
        <div className={isMobile ? clazz.container_mobile : clazz.container}>
            <div>
                <img alt="basil field" className={isMobile ? clazz.mobile_image : clazz.image} src={ProductImage} />
                <div className={isMobile ? clazz.mobile_scrollable_text : clazz.scrollable_text}>
                    <p> <b>Basil</b></p>
                    <p>
                        Basil oil is a remarkable product with substantial sales potential. Derived from high-quality basil plants, this oil offers a range of enticing selling points that make it a desirable choice for customers.
                    </p><p>
                        First and foremost, the invigorating and herbaceous aroma of basil oil sets it apart as a valuable ingredient in the fragrance industry. Its distinctive scent, characterized by its fresh, green, and slightly spicy notes, adds a vibrant and uplifting touch to perfumes and personal care products. This makes basil oil a sought-after option for manufacturers and individuals looking for products that exude a revitalizing and natural fragrance.
                    </p><p>
                        Furthermore, basil oil holds significant therapeutic properties, making it a popular choice in the wellness sector. Its stimulating and energizing effects make it suitable for aromatherapy applications. By promoting mental clarity, reducing fatigue, and providing a sense of focus, basil oil appeals to customers seeking natural remedies to enhance their well-being and mental performance.
                    </p><p>
                        In addition to its fragrance and therapeutic benefits, basil oil has noteworthy applications in skincare. With its antimicrobial and anti-inflammatory properties, it is often used in skincare formulations to address skin issues such as acne, blemishes, and irritations. Basil oil's soothing and purifying effects make it an attractive choice for customers looking for natural and effective skincare solutions.
                    </p><p>
                        Moreover, basil oil finds its place in the culinary world. Its distinct flavor, reminiscent of sweet and peppery undertones, adds depth and complexity to various culinary creations. It is used in sauces, dressings, marinades, and herbal infusions, providing a fresh and aromatic twist to dishes and beverages.
                    </p><p>
                        In summary, basil oil presents a range of sales points that make it an appealing product in multiple industries. From its invigorating fragrance and therapeutic benefits in aromatherapy to its potential in skincare and culinary applications, this oil offers versatility and wide-ranging benefits. By leveraging these unique selling points, businesses can market basil oil as a premium and multifunctional product, attracting customers seeking natural, refreshing, and high-quality solutions for their fragrance, wellness, skincare, and culinary needs.
                    </p>
                </div>
            </div>

            <div className={clazz.inner_col_containers}>
                <div>
                    <p>
                        <b>HSN Code : 33012590</b>
                    </p>
                </div>
                <div>
                    <p>
                        <b>Product Reports</b>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default basil;